import React, { useState } from 'react';
import { useStaticQuery } from "gatsby";

export const HowItWorks = () => {
  const [active, setActive] = useState(1);
  const data = useStaticQuery(graphql`
    query {
      contentfulHoeWerktHet {
        step1Description {
          step1Description
        }
        step1Image {
          file {
            url
          }
          title
        }
        step1Title
        step2Description {
          step2Description
        }
        step2Image {
          file {
            url
          }
          title
        }
        step2Title
        step3Description {
          step3Description
        }
        step3Image {
          file {
            url
          }
          title
        }
        step3Title
        title
      }
    }
  `);
 return (
   <>
    <h2 className="text-center text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
      {data.contentfulHoeWerktHet.title}
    </h2>
    <div className="py-12 bg-white">
      <div className="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 text-center">
        <h2 className="sr-only">{data.contentfulHoeWerktHet.step1Title}</h2>
        <dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-8">
          <div onClick={() => setActive(1)}>
            <div className="mt-5 ">
              {/* avoid tree shaking for bg-green-100 */}
              {active !== 1 && (
                <dt className="bg-gray-100 hover:bg-green-200 cursor-pointer text-gray-900 text-lg hover:text-gray-700 px-3 py-2 font-medium text-sm rounded-md">
                  {data.contentfulHoeWerktHet.step1Title}
                </dt>
              )}
              {active === 1 && (
                <dt className="bg-green-100 hover:bg-green-200 cursor-pointer text-gray-900 text-lg hover:text-gray-700 px-3 py-2 font-medium text-sm rounded-md">
                  {data.contentfulHoeWerktHet.step1Title}
                </dt>
              )}
              <dd className="mt-2 text-base text-gray-500">
                {data.contentfulHoeWerktHet.step1Description.step1Description}
              </dd>
            </div>
          </div>

          <div onClick={() => setActive(2)}>
            <div className="mt-5">
              <dt className={`bg-${active === 2 ? "green" : "gray"}-100 hover:bg-green-200 cursor-pointer text-gray-900 text-lg hover:text-gray-700 px-3 py-2 font-medium text-sm rounded-md`}>
                {data.contentfulHoeWerktHet.step2Title}
              </dt>
              <dd className="mt-2 text-base text-gray-500">
                {data.contentfulHoeWerktHet.step2Description.step2Description}
              </dd>
            </div>
          </div>

          <div onClick={() => setActive(3)}>
            <div className="mt-5">
              <dt className={`bg-${active === 3 ? "green" : "gray"}-100 hover:bg-green-200 cursor-pointer text-gray-900 text-lg hover:text-gray-700 px-3 py-2 font-medium text-sm rounded-md`}>
                {data.contentfulHoeWerktHet.step3Title}
              </dt>
              <dd className="mt-2 text-base text-gray-500">
                {data.contentfulHoeWerktHet.step3Description.step3Description}
              </dd>
            </div>
          </div>
        </dl>
        <div className="m-8">
          <img className={`w-full${active === 1 ? "" : " hidden"}`} src={data.contentfulHoeWerktHet.step1Image.file.url} alt="" />
          <img className={`w-full${active === 2 ? "" : " hidden"}`} src={data.contentfulHoeWerktHet.step2Image.file.url} alt="" />
          <img className={`w-full${active === 3 ? "" : " hidden"}`} src={data.contentfulHoeWerktHet.step3Image.file.url} alt="" />
        </div>
      </div>
    </div>
  </>
 );
};